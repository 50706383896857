/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"
import { Link } from "gatsby"
// import { Location } from "@reach/router"
import { TheSeo } from "x"

const basicTemplate = props => {
	const { pageContext } = props
	const { pageContent, links } = pageContext

	return (
		<Fragment>
			<TheSeo title={pageContext.title} />
			<div>
				<ul>
					{pageContent.map((data, index) => {
						return <li key={`content_item_${index}`}>{data.item}</li>
					})}
				</ul>
				<ul>
					{links.map((item, index) => {
						return (
							<li key={`link_${index}`}>
								<Link to={item.to}>{item.to}</Link>
							</li>
						)
					})}
				</ul>
			</div>
		</Fragment>
	)
}
export default basicTemplate
